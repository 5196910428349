@import "../../main";

.contact {
  box-shadow: 0px 6px 4px -6px #ada8a8;
  margin-bottom: 10px;
  background-color: #fff;
  padding-bottom: 0px;
  color: #1c1c1c;
  padding-top: 5px;
  .info-circle-tooltip-icon {
    width: 1rem;
    height: 1rem;
    position: relative;
    top: -1px;
  }
}

.contact-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 2fr 1fr;
  grid-auto-flow: dense;
  margin: 0 15% 0 15%;
  padding-top: 0px; // chnaged to 0 from 20px by mallik
  position: relative;
}

.loading-contact {
  .loading-contact-grid {
    width: 70%;
    margin: auto;
    display: flex;
    .loading-contact-item {
      flex-basis: 60%;
      padding: 20px;
    }
    .loading-contact-item:last-of-type {
      flex-basis: 40%;
      padding: 20px 44px 20px 100px;
    }
  }
}

.contact-item:nth-child(odd) {
  box-sizing: border-box;
  height: fit-content;
  break-inside: avoid;
  display: inline-block;
  margin-bottom: 2vh;
}

.contact-item:nth-child(even) {
  box-sizing: border-box;
  height: fit-content;
  break-inside: avoid;
  display: inline-block;
}

.contact-card-wrapper {
  display: flex;
  justify-content: space-between;
  vertical-align: top;
}

.profile-photo {
  display: flex;
  flex: 1;
  margin: 0 3vh 0 0; // previous properties 1vh 2vh 0 0
  flex-wrap: nowrap;
  width: fit-content;
  height: fit-content;
}

.profile-info {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  align-content: top;
  height: fit-content;
  margin-top: 25px; // added by Mallik for new laout
}
.image {
  position: relative;
}

.image {
  img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
  }
  &:hover .upload {
    display: block;
    border-radius: 50%;
    position: absolute;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.6;
    top: 0;
  }
}

.upload {
  display: none;
}

.icon {
  position: absolute;
  cursor: pointer;
  top: 35%;
  left: 40%;
  &:hover {
    transform: scale(1.3);
    transition: all 0.2s ease-in-out;
  }
}

.WorkDay {
  @include Container;
  box-sizing: border-box;
}

.address {
  display: flex;
  font-size: 16px;
  font-weight: normal;
  font-family: Arial, sans-serif;
  margin-top: 5px;

  > div {
    margin-right: 10px;
  }
}

.Profile-content {
  padding-top: 0px;
  position: relative;
  border-bottom: #dddddd;
  background-color: #fafafa;
  padding-bottom: 10px;
  border-top: #66c2d6 2px solid;
  height: 54px;
}
.Profile-completion {
  //display: grid;
  grid-gap: 12px;
  grid-template-columns: 1fr 1fr 2fr;
  grid-auto-flow: dense;
  margin: 0 7% 0 26%;
  padding-top: 0px;
  position: relative;
  border-bottom: #dddddd;
  background-color: #fafafa;
  margin-left: 14%!important;
  width:100%
}
.contactName {
  font-family: ShellLight, sans-serif;
  font-weight: 500;
  font-size: 27px;
  line-height: 115%;
  letter-spacing: -0.03em;
  margin-bottom: 10px;
}
.contactTitle {
  font-family: ShellBold, sans-serif;
  font-size: 16px;
  line-height: 115%;
  text-transform: Capitalize;
}
.contactCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 72%;
  margin: 0 auto;
  padding-top: 15px;
}
.contactCol1 {
  width: 15%;
  margin-bottom: 5px;
}
.contactCol2 {
  width: 46%;
  margin-right: 15px;
  margin-left: 10px;
  margin-top: 10px;
}
.contactCol3 {
  width: 39%;
  padding: 2px;
}
.contactEdit {
  color: #a7a9ac;
  margin-left: 81%;
  margin-top: 5%;
  padding-left: 2px;
  cursor: pointer;
  &:hover {
    color: #0099ba;
  }
}
.contactPhone {
  font-family: Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.05em;
  width: 80%;
  word-break: break-all;
  padding-top: 5%;
}
.contactEmail {
  font-family: Arial, sans-serif;
  font-size: 16px;
  letter-spacing: 0.05em;
  padding-top: 5px;
}

.Chat
{
  margin-top: 4%;
  margin-left: 7%;
}
.addFlex {
  display: flex;
  margin-left: 26%;
  padding-bottom: 15px;
  padding-top: 10px;
}
.locationFlex {
  display: flex;
  font-size: 14px;
  padding-top: 3px;
}
