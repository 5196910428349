:root {
  typo: {
    text: {
      --heading: ShellMedium;
      --list: ShellBook;
      --paragraph: Arial Regular;
    }
    numbers: {
      heading: Roboto;
    }
    size: {
      xxs: 14px;
      xs: 16px;
      sm: 18px;
      md: 20px;
      lg: 26px;
      xl: 28px;
      xxl: 30px;
    }
  }
  color: {
    primarydark: #0099ba;
    primarymid: #66c2d6;
    primarylight: #99d6e3;
    primaryextralight: #ccebf1;
    textdark: #58595b;
    textmid: #808285;
    textlight: #a7a9ac;
    textextralight: #dddddd;
    error: #e58271;
    link: #007fff;
    bg: #f4f4f4;
  }
  pad: {
    sm: 0.25rem;
    md: 0.5rem;
    lg: 1rem;
    xl: 2rem;
  }
  radius: {
    sm: 0.25rem;
    md: 0.5rem;
    lg: 1rem;
    xl: 2rem;
  }
  width: {
    sm: 50%;
    md: 75%;
    lg: 100%;
  }
  shadow: {
    lg: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
      0 10px 10px -5px rgba(0, 0, 0, 0.04);
  }
}

@font-face {
  font-family: ShellBook;
  src: url("../src/assets/fonts/ShellBook.otf"),
    url("../src/assets/fonts/ShellBook.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ShellBold;
  src: url("../src/assets/fonts/ShellBold.otf"),
    url("../src/assets/fonts/ShellBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: ShellBook;
  src: url("../src/assets/fonts/ShellBookItalic.otf"),
    url("../src/assets/fonts/ShellBookItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: ShellBookMedium;
  src: url("../src/assets/fonts/ShellMedium.otf"),
    url("../src/assets/fonts/ShellMedium.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ShellMedium;
  src: url("../src/assets/fonts/ShellMedium.otf"),
    url("../src/assets/fonts/ShellMedium.woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ShellLight;
  src: url("../src/assets/fonts/ShellLight.otf"),
    url("../src/assets/fonts/ShellLight.woff");
  font-weight: normal;
  font-style: normal;
}

@mixin Container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: top;
  flex-wrap: wrap;
}

@mixin transform($property) {
  -webkit-transform: $property;
  -ms-transform: $property;
  transform: $property;
}

body {
  margin: 0;
  font-family: ShellBook, Arial, Helvetica, sans-serif;
  // overflow-y: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #58595b;
}

.wrapper {
  display: grid;
  grid-template-rows: 1fr auto;
}

.grid {
  display: grid;
  grid-gap: 20px;
  grid-template-columns: 2fr 1fr;
  grid-auto-rows: 0;
  grid-auto-flow: dense;
  margin: 2% 20% 2% 20%;
}

.item:nth-child(odd) {
  height: fit-content;
  background-color: white;
}

.item:nth-child(even) {
  height: fit-content;
  background-color: white;
}

.cards {
  background-color: #f4f4f4;
}

.card {
  border: 1px solid #808285 !important;
  border-radius: 0px !important;
  display: block;
  color: #1c1c1c;
  .card-body {
    position: relative;
    //border-bottom: 1px solid #808285 !important;
    padding: 24px 30px 10px 30px;
    flex-grow: revert;
    font-size: 14px;
  }
  .card-title {
    font-family: ShellBold, sans-serif;
    font-size: 18px;
    margin-bottom: 1.5rem;
  }
}

.card-text:last-child {
  margin-bottom: revert !important;
}
div .card:empty {
  display: none;
}

.header {
  display: grid;
  line-height: 20px !important;
}
.topbar-header {
  background-color: #f4f4f4;
}

.topbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 16px 15% 16px 15%;
  border-bottom: 1px solid #ccc;
  background-color: #fff;

  span {
    font-size: 26px !important;
  }
  a {
    &:hover {
      text-decoration: none !important;
      cursor: pointer !important;
    }

    &:active {
      text-decoration: none !important;
      cursor: pointer !important;
    }
  }
  img {
    max-width: 18% !important;
  }
}

.footer {
  background-color: #ccebf1;
  text-align: center;
  justify-content: center;
}

.c {
  margin: 0 10% 0 10%;
  column-count: 2;
  column-gap: 30px;
}

.child {
  margin-bottom: 16px;
  break-inside: avoid;
  display: inline-block;
  justify-items: center;
  justify-content: center;
}

.edit {
  position: absolute;
  top: 1.25rem;
  right: 2.25rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}

.placeholder {
  float: left;
}

.child:nth-child(odd) {
  width: 100%;
  min-height: 100px;
}

.child:nth-child(even) {
  width: 100%;
  object-fit: cover;
  min-height: 40px;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #a7a9ac;
}
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #a7a9ac;
  opacity: 1;
}
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #a7a9ac;
  opacity: 1;
}
:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #a7a9ac;
}
::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #a7a9ac;
  font-size: 12px;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: #a7a9ac;
}

.shellbook {
  font-family: ShellBook, sans-serif;
}
.modal-header {
  border-top: none !important;
  border-bottom: 1px solid #1c1c1c !important;
}
.modal-footer {
  border-top: none !important;
}

.modal-body {
  color: #1c1c1c !important;
}

.button.close {
  color: #58595b !important;
}

.icon-default {
  color: #a7a9ac;
  cursor: pointer;
  width: 3.25rem; // changed 1.25 to 3.25 by mallik
  height: 1.25rem;
  padding-left: 30px;
  &:hover {
    color: #0099ba;
  }

  &:active {
    color: #0099ba;
  }
}

.icon-defaultNoLink {
  color: #a7a9ac;
  //cursor: pointer;
  width: 3.25rem; // changed 1.25 to 3.25 by mallik
  height: 1.25rem;
  padding-left: 20px;
}
.icon-remove {
  color: #a7a9ac;
  cursor: pointer;
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 14px;
  &:hover {
    color: #dc3545;
  }

  &:active {
    color: #dc3545;
  }
}

.icon-add {
  color: #0099ba;
  cursor: pointer;
  width: 2.2rem;
  height: 2.2rem;
  margin-left: 14px;
}
.text-muted {
  color: #1c1c1c !important;
  margin-left: 1em;
}
.textMuted {
  margin-left: 1em;
}
.btn-danger {
  background-color: #e58271 !important;
  border-color: #e58271 !important;
  margin-right: "1.5rem";
  width: "7vw";
}

button.button-cancel-menu {
  background-color: #fff;
  color: black;
  margin-right: "1rem";
  width: "7vw";
}

#info-tooltip > .tooltip-inner {
  max-width: 100%;
  white-space: normal;
  text-align: left !important;
  border: 1px solid #062e56;
  font-family: Arial, sans-serif;
  color: #f4f4f4 !important;
}

#info-tooltip > .tooltip-arrow {
  border-top: 5px solid #062e56;
}

.cards-container {
  // display: flex;
  background-color: #f4f4f4;
  padding: 20px 5%;

  > div:first-child {
    margin-right: 20px;
    float: left;
    width: calc(53% - 20px);
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }

  > div:last-child {
    float: right;
    width: 47%;
    display: inline-flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 20px;
  }

  &:after {
    content: " ";
    clear: both;
  }
}

ol li {
  line-height: 22px;
  padding-bottom: 1em !important;
  word-break: break-word;
}

.rdw-editor-wrapper {
  display: flex;
  flex-direction: column-reverse;
}

.rdw-editor-main {
  max-height: 60vh;
  overflow: auto;
  border: 1px solid #f1f1f1;
  padding: 10px;
  border-bottom: 0px;
  border-radius: 0.25rem;
  z-index: 0;
}

.text-reveal-panel {
  transition: all 2s;
  max-height: 220px;
  overflow: hidden;
  margin-bottom: 30px;
}

.show-all {
  max-height: initial;
}

.text-reveal-action {
  background-image: linear-gradient(to bottom, transparent, white);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 140px 0 10px 30px;
  color: #58595b;
  font-family: Arial, sans-serif;
  font-weight: normal;
  text-decoration: underline;
  line-height: 220%;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    color: #000FFF;
  }
}

.text-reveal-active {
  width: 100%;
  display: inline-block;
  position: initial;
  padding: 0px;
}
#career-and-history-card {
  .list-group-item {
    padding-left: 0px;
    padding-top: 20px;
  }
}

.public-DraftStyleDefault-ul li,
.public-DraftStyleDefault-ol li,
.text-reveal-panel ul li,
.text-reveal-panel ol li {
  padding-bottom: 0px !important;
  line-height: inherit !important;
}

.DraftEditor-editorContainer {
  font-family: Arial, Helvetica, sans-serif !important;
}

.loading-container.container {
  min-height: 140px;
}
//@include

.tag {
  font-family: ShellBook, sans-serif;
  font-style: normal;
  //margin-right: 0.3rem;
  line-height: 160%;
  overflow-wrap: break-word;
  color: #1c1c1c;
  text-decoration: none;
}

.tag:hover {
  color: #000FFF;
  cursor: pointer;
  text-decoration: underline;
}

.tag::after {
  content: ";  ";
}

.tag-single::after {
  content: none;
}
.tag-comma::after {
  content: ",  ";
}

.subheader {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 160%;
  padding-top: 20px;
}
.subheaderNoPadding {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 160%;
}
.subHeading {
  font-family: Arial, sans-serif;
  font-weight: bold;
  line-height: 160%;
  padding-top: 10px;
}
.shellSmallText {
  font-family: ShellBook, sans-serif;
  font-size: small;
}
.showLink {
  background: #fff;
  border: none;
  color: #58595b;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 220%;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
    color: #000FFF;
  }
}

.css-yk16xz-control {
  border: 1px solid #1c1c1c !important;
}
.form-control {
  border: 1px solid #1c1c1c !important;
}
.bottomBorder {
  border-bottom: 1px solid #808285 !important;
}
.efTour {
  font-size: 14px;
  max-width: 580px !important;
  font-family: Arial, sans-serif;
}
.editInfoMain {
  border-bottom: none !important;
}
.editInfoHeader {
  font-family: ShellBook, sans-serif;
  font-style: italic;
  color: #58595b;
  text-decoration: underline;
  padding: 10px 15px 5px 15px;
}
.editInfoHeaderText {
  font-family: ShellBook, sans-serif;
  font-style: italic;
  color: #58595b;
  padding: 5px 0px 0px 15px;
}
.editInfoBody {
  padding: 0 15px 15px 15px;
  font-family: ShellBook, sans-serif;
  font-size: 15px;
}
.editInfoSubHeader {
  font-family: Arial, sans-serif;
  font-weight: bold;
  padding: 10px 0;
}
.editInfoBodyText {
  padding: 0 0 10px 15px;
}
.divCenter {
  padding-top: 15px;
  text-align: center;
}
.btnProceedTo {
  color: #fff;
  border-radius: 5px;
  padding: 3px 20px 7px 20px;
  background-color: #17a2b8;
  border: none;
  &:hover {
    background-color: #138496;
  }
}
.topBanner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 15px 1%;
  border-bottom: 1px solid #ccc;
  background-color: #fff;
  height: 70px;
  span {
    font-size: 26px !important;
  }
  a {
    &:hover {
      text-decoration: none !important;
      cursor: pointer !important;
      color: #000FFF !important;
    }

    &:active {
      text-decoration: none !important;
      cursor: pointer !important;
    }
  }
  img {
    max-width: 18% !important;
  }
}
.headerCol1 {
  width: 50%;
  text-align: left;
}
.headerCol2 {
  width: 30%;
  padding-right: 1%;
}
.headerCol3 {
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
.feedbackLink {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-decoration: underline;
  text-align: center;
  cursor: pointer;
  color: #1c1c1c;
  :hover {
    color: #0099ba;
  }
}

.feedbackIcon {
  text-align: center;
  padding-top: 2px;
}
.searchSmallText {
  font-family: Arial, sans-serif;
  font-size: 9px;
}
.search {
  border: 1px solid #cccccc;
  border-radius: 22.5px;
  width: 100%;
  padding: 6px 23px 6px 14px;
  outline: 0;
  background-color: #fff;
  font-size: 14px;
  font-weight: normal;
  font-family: ShellBook, sans-serif;
  height: 35px;
}
.search-icon {
  position: absolute;
  top: 25px;
  right: 22.5%;
  width: 14px;
  cursor: pointer;
}
.clear-icon {
  position: absolute;
  top: 24px;
  right: 225px;
  width: 12px;
  cursor: pointer;
  visibility: hidden;
}
ul.bulletPoint {
  line-height: 28px;
}

.workingDays {
  padding-left: 1px;
}
.addTermSetLink {
  font-size: 14px;
  padding: 5px 0 0 0;
  font-weight: bold;
  color: #58595b;
  text-decoration: underline;
  :hover {
    color: #0099ba;
  }
  .clearAll {
    font-family: Arial, sans-serif;
    color: #009eb4;
    margin-right: 5px;
  }
}

.hyperLink {
  color: #1c1c1c;
  :hover {
    color: #0099ba;
  }
}
.topNavButton {
  height: 30px;
}
.editButton {
  margin-right: "1.5rem" !important;
  width: "7vw";
  padding: ".1px";
  height: "30px";
}
// 50%
@media (max-width: 2600px) {
  .headerCol1 {
    width: 52%;
  }
  .headerCol3 {
    width: 18%;
  }
  .search-icon {
    right: 20.5%;
  }
  .clear-icon {
    right: 11.5% !important;
  }
  .addFlex {
    margin-left: 20.1% !important;
  }
  .image:hover {
    width: 82%;
  }
  .contactCol1 {
    width: 8% !important;
  }
  .contactCol2 {
    width: 54.5% !important;
  }
  .contactCol3 {
    width: 36.5% !important;
  }
  .Profile-completion {
    grid-gap: 110px !important;
    margin: 0 7% 0 20.1% !important;
  }
  .educationCol2 {
    width: 10.5% !important;
  }
  .contactEdit {
    margin-left: 91.4% !important;
  }
}
// 67%
@media (max-width: 2000px) {
  .headerCol1 {
    width: 50%;
  }
  .headerCol3 {
    width: 20%;
  }
  .search-icon {
    right: 22.5%;
  }
  .clear-icon {
    right: 13.7% !important;
  }
  .addFlex {
    margin-left: 21.6% !important;
  }
  .image:hover {
    width: 88%;
  }
  .contactCol1 {
    width: 10% !important;
  }
  .contactCol2 {
    width: 52% !important;
  }
  .contactCol3 {
    width: 37.5% !important;
  }
  .Profile-completion {
    grid-gap: 102px !important;
    margin: 0 11% 0 21.8% !important;
  }
  .educationCol2 {
    width: 13.6% !important;
  }
  .contactEdit {
    margin-left: 88.2% !important;
  }
}
// 75%
@media (max-width: 1800px) {
  .headerCol1 {
    width: 48%;
  }
  .headerCol3 {
    width: 22%;
  }
  .search-icon {
    right: 24.5%;
  }
  .clear-icon {
    right: 16% !important;
  }
  .addFlex {
    margin-left: 22.8% !important;
  }
  .image:hover {
    width: 87%;
  }
  .contactCol1 {
    width: 11.5% !important;
  }
  .contactCol2 {
    width: 50% !important;
  }
  .contactCol3 {
    width: 37.5% !important;
  }
  .Profile-completion {
    grid-gap: 91px !important;
    margin: 0 12% 0 22.8% !important;
  }
  .educationCol2 {
    width: 16% !important;
  }
  .contactEdit {
    margin-left: 87% !important;
  }
}
// 80%
@media (max-width: 1600px) {
  .headerCol1 {
    width: 48%;
  }
  .headerCol3 {
    width: 22%;
  }
  .search-icon {
    right: 25%;
  }
  .clear-icon {
    right: 16.5% !important;
  }
  .addFlex {
    margin-left: 23% !important;
  }
  .image:hover {
    width: 92%;
  }
  .contactCol1 {
    width: 11.7% !important;
  }
  .contactCol2 {
    width: 50% !important;
  }
  .contactCol3 {
    width: 37.7% !important;
  }
  .Profile-completion {
    grid-gap: 87px !important;
    margin: 0 12% 0 22.9% !important;
  }
  .educationCol2 {
    width: 17.4% !important;
  }
  .contactEdit {
    margin-left: 86% !important;
  }
}
// 90%
@media (max-width: 1430px) {
  .headerCol1 {
    width: 46%;
  }
  .headerCol3 {
    width: 24%;
  }
  .search-icon {
    right: 27%;
  }
  .clear-icon {
    right: 18.6% !important;
  }
  .addFlex {
    margin-left: 24% !important;
  }
  .image:hover {
    width: 92%;
  }
  .contactCol1 {
    width: 13% !important;
  }
  .contactCol2 {
    width: 47.5% !important;
  }
  .contactCol3 {
    width: 38% !important;
  }
  .Profile-completion {
    grid-gap: 68px !important;
    margin: 0 12% 0 24% !important;
  }
  .educationCol2 {
    width: 19.7% !important;
  }
  .contactEdit {
    margin-left: 84.5% !important;
  }
}
//100%
@media (max-width: 1280px) {
  .headerCol1 {
    width: 45%;
  }
  .headerCol3 {
    width: 25%;
  }
  .search-icon {
    right: 27%;
  }
  .clear-icon {
    right: 19.2% !important;
  }
  .addFlex {
    margin-left: 24.7% !important;
  }
  .image:hover {
    width: 95%;
  }
  .contactCol1 {
    width: 14% !important;
  }
  .contactCol2 {
    width: 46.5% !important;
  }
  .contactCol3 {
    width: 38.8% !important;
  }
  .Profile-completion {
    grid-gap: 57px !important;
    margin: 0 12% 0 23.6% !important;
  }
  .educationCol2 {
    width: 22.7% !important;
  }
  .contactEdit {
    margin-left: 83% !important;
  }
}
// 110%
@media (max-width: 1180px) {
  .headerCol1 {
    width: 44%;
  }
  .headerCol3 {
    width: 26%;
  }
  .search-icon {
    right: 29%;
  }
  .clear-icon {
    right: 21% !important;
  }
  .contactCol2 {
    margin-left: 20px;
  }
  .contactCard {
    width: 80% !important;
  }
  .addFlex {
    margin-left: 22% !important;
  }
  .Profile-completion {
    grid-gap: 46px !important;
    margin: 0 6% 0 21.8% !important;
  }
  .cards-container {
    padding: 20px 10%;
  }
  .image:hover {
    width: 95%;
  }
  .educationCol2 {
    width: 22.5% !important;
  }
  .contactEdit {
    margin-left: 83% !important;
  }
}
// 125%
@media (max-width: 1024px) {
  .headerCol1 {
    width: 41%;
  }
  .headerCol3 {
    width: 29%;
  }
  .search-icon {
    right: 32%;
  }
  .clear-icon {
    right: 24% !important;
  }
  .contactCol2 {
    margin-left: 20px;
  }
  .contactCard {
    width: 88% !important;
  }
  .image:hover {
    width: 99%;
  }
  .addFlex {
    margin-left: 19.1% !important;
  }
  .Profile-completion {
    grid-gap: 50px !important;
    margin: 0 4% 0 19% !important;
  }
  .cards-container {
    padding: 20px 6%;
  }
  .workingDays {
    padding-left: 8px;
  }
  .educationCol2 {
    width: 23.5% !important;
  }
  .contactEdit {
    margin-left: 82.5% !important;
  }
}
