.btn-info:hover {
  color: #fff;
  text-decoration: none;
  background-color: #138496;
  border-color: #117a8b;
}

.dropdown-item,
.dropdown-item:active {
  padding: 0px 15px 0px 15px !important;
  color: #58595b !important;
  text-decoration: none;
  background-color: #fff !important;
}

.dropdown-item:hover {
  padding: 0px 15px 0px 15px !important;
  color: #58595b !important;
  text-decoration: none;
  background-color: #fff !important;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #0099ba !important;
  border-color: #0099ba !important;
}
.btn-add-section {
  font-family: ShellBook, sans-serif;
  font-weight: 300;
  //line-height: 0.2rem !important;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: fit-content !important;
  margin: 0.125rem 0 0;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-divider {
  height: 0;
  margin: 5px 0 !important;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}
