#page-loader-wrapper {
  z-index: 2;
  height: 100%;
  width: 100%;
  background-color: #fff;
  font-family: ShellBookMedium, sans-serif;

  > #page-loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 400px;
  }

  img {
    display: flex;
    justify-content: center;
    object-fit: scale-down;
  }

  .message {
    z-index: 1999;
    display: flex;
    justify-content: center;
  }
}
