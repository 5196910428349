.header {
  font-family: ShellMedium, sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 27px;
  color: #1c1c1c;

  .info-circle-tooltip-icon {
    width: 17px;
    height: 17px;
    position: relative;
    left: 6px;
    //top: -1px;
  }
}

.body {
  white-space: pre-wrap;
  overflow-wrap: break-word;
  color: #58595b;
  font-family: ShellBook, sans-serif;
  font-size: 16px;
}

.title {
  font-size: 18px;
}
