.education {
  font-family: ShellBook, sans-serif;
  font-size: 14px;
}
.educationCard {
  display: flex;
  font-size: 14px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 5px;
}
.educationCol1 {
  width: 77%;
  font-family: Arial, sans-serif;
  font-weight: bold;
  font-size: 14px;
}
.educationCol2 {
  width: 23%;
}
.textSmall {
  font-family: ShellBook, sans-serif;
  font-size: small;
}
