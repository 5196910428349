.featured-content-ul {
	position: relative;
	list-style: none;
	margin-left: 0;
	padding-left: 1.2em;

	li {
		padding-bottom: 0.5em;
		word-break: break-all;

		&::before {
			content: '-';
			position: absolute;
			left: 0;
		}

		a {
			color: #58595b;
			transition: 0.5s;

			&:hover {
				color: #0099ba;
			}
		}
	}
}

.hyperlink a:hover{
	color: #000FFF !important;
}
