.sme-details-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
 // margin-bottom: 2%;

  .sme-details {
    margin-bottom: 20px;
    width: 48%;
    position: relative;
    box-sizing: border-box;
    max-width: 50%;
    margin-right: 1%;
    padding-left: 1%;
    padding-top: 1%;
  
    //display: flex;

    .star-img {
      height: 20px;
      width: 20px;
      top: 28px;
      left: 26px;
      background-repeat: no-repeat;
      background-size: 70%;
    }

    img {
      width: 40px;
      height: 40px;
      border-radius: 20px;
      margin-right: 10px;
    }

    .sme-name {
      width: 200px;
      color: #58595b !important;
    }
  }
}

.sme-name {
  width: 200px;
  color: #58595b;
}


.sme-header
{
  width:100% !important;
  max-width: 100% !important;
}

.sme-toogle{
display:none !important;
}

.sme-underline:hover {
  text-decoration: underline;
  color: #000FFF;
  cursor: pointer;
}

.skeleton-loading {
  text-align: left;
  line-height: 38px;
}

.connector
{
  width: 0.55px;
  height: 40px;
 margin-left: 45%;
  
  border: 0.5px solid;
 

}
