.info-wrapper {
  height: auto;
  position: relative;
  width: auto;
  padding: 0 0 2.5em 0;
}
.info {
  max-height: 180px;
  height: auto;
  width: auto;
  overflow: hidden;
  position: relative;
  text-align: justify;
  box-sizing: border-box;
  padding: 20px;
}
.info:after,
.aftershadow {
  bottom: 0;
  width: auto;
  height: auto;
}

.info-wrapper a {
  left: 50%;
  position: relative;
  font: 700 0.67em/1.25em Arial, sans-serif;
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}
.less {
  height: auto;
  display: none;
}
.more {
  display: none;
}
