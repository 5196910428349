.hrSep {
  margin: 1rem 1rem;
  border: 0;
  border-top: 1px solid #dddddd;
}
.jobTitle {
  font-family: ShellBook, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 180%;
  color: #58595b;
  overflow-wrap: break-word;
}
.companyPeriodLocation {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 220%;
  color: #58595b;
  overflow-wrap: break-word;
  width: fit-content;
  margin-right: 10px;
}
.jobDescription {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 180%;
  color: #58595b;
  overflow-wrap: break-word;
}
.capitalProjectBox {
  // margin-left: 10px;
  background: #d1ebf0;
  border-radius: 4px;
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 220%;
  text-align: center;
  letter-spacing: 0.02em;
  color: #58595b;
  width: fit-content;
}
.capitalProjectName {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 220%;
  margin-left: 10px;
  padding-right: 10px;
}
.capitalProjectHeading {
  font-family: Arial, sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 220%;
  padding-left: 10px;
}

.jobHistory {
  display: flex;
  justify-content: space-between;
}

.showMore {
  background: white;
  line-height: 220%;
  margin-left: 29px;
}
.showMore:hover {
  cursor: pointer;
  text-decoration: none;
}

.required {
  color: #dc3545;
}

.title {
  font-weight: bold;
  color: #58595b;
}

.react-datepicker-wrapper input {
  width: 140px !important;
  color: #58595b;
  border-color: #ced4da !important;
  border-radius: 5px !important;
  box-shadow: none;
  //margin-right: 10px;
  padding: 5px;
}

.calendar {
  margin-left: -2vw !important;
  position: relative;
}

.required-message {
  color: #dc3545;
}

#date-picker-container .date-picker-form-group {
  display: flex;
  flex-direction: column;
  position: relative;

  .react-datepicker-wrapper {
    padding-right: 25px;
  }

  svg {
    position: absolute;
    right: 30px;
    top: 42px;
  }
}

.job-actions-container {
  display: flex;
  margin-right: -10px;
}

.project-badge {
  display: inline-block;
  background-color: #ccebf1;
  color: #58595b;
  font-size: small;
  padding: 0.2rem 0.4rem;
  border-radius: 5px;
}

.react-datepicker__navigation--next 
{

  border-left-color:#595959  !important;
}

.react-datepicker__navigation--previous
{
  border-right-color:#595959  !important;
}

.react-datepicker__year-read-view--down-arrow
{
  border-top-color:#595959  !important;
}



