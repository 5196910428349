.spanStyling {
  margin-right: 1%;
}

.skillContent {
  font-family: ShellBook, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 220%;
  overflow-wrap: break-word;
  color: #58595b;
  text-decoration: none;
}

.skillContent:hover {
  color: #0099ba;
  cursor: pointer;
  text-decoration: underline;
}

.required-message {
  color: #dc3545;
}
